<template>
  <!-- Image and text -->
  <nav class="navbar navbar-expand-lg navbar-light" >
    <div class="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
      <a class="navbar-brand" href="#">
        <img src="../../public/img/Rose_Logo.png" width="80" height="50" class="d-inline-block align-top" alt="">
      </a>
      <div class="navbar-nav">
        <router-link to="/" class="nav-item nav-link active" href="#">Home</router-link>
        <router-link to="/login" class="nav-item nav-link" href="#">Login</router-link>
        <router-link to="/register" class="nav-item nav-link">Register</router-link>
        <router-link to="#" class="nav-item nav-link disabled" href="#">Download</router-link>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
</script>