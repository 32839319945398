<template>
  <div class="container vh-100">
    <h1 class="text-center">Register</h1>
    <form @submit.prevent="registerUser" class="w-50 mx-auto mt-5">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" v-model="username" />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" v-model="email" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control"  v-model="password" />
      </div>
      <button type="submit" class="btn btn-primary mt-3">Register</button>
    </form>
  </div>
</template>


<script>
    import axios from 'axios';

    export default {
        name: 'UserRegister',
        data() {
            return {
                username: '',
                email: '',
                password: '',
            };
        },
        methods: {
            registerUser() {
                axios.post('https://api.jibestudio.net:3000/users/register', {
                    username: this.username,
                    email: this.email,
                    password: this.password
                })
                .then(response => {
                    console.log('Registration successful', response);
                    alert('Registration successful');
                })
                .catch(error => {
                    console.error('Registration failed', error);
                    alert(error);
                });
            }
        }
    };
</script>