<template>
    <div class="container vh-100">
        <h1 class="text-center">Login</h1>
        <form @submit.prevent="loginUser"  class="w-50 mx-auto mt-5">
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" class="form-control" v-model="username" />
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" v-model="password" />
            </div>
            <button type="submit" class="btn btn-primary mt-3">Login</button>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'UserLogin',
        data() {
            return {
                username: '',
                password: '',
            };
        },
        methods: {
            loginUser() {
                axios.post('https://api.jibestudio.net:3000/users/login', {
                    username: this.username,
                    password: this.password
                })
                .then(response => {
                    console.log('Login successful', response);
                    alert('Login successful');
                })
                .catch(error => {
                    console.error('Login failed', error);
                    alert(error);
                });
            }
        }
    };
</script>