<template>
    <div>
        <h1>News</h1>
        <ul v-if="news.length">
            <li v-for="item in news" :key="item.id">
                <h3>{{ item.title }}</h3>
                <p>{{ item.content }}</p>
                <small>{{ item.date }}</small>
            </li>
        </ul>
        <p v-else>No news available right now.</p>
    </div>
</template>

<script>
    import axios from 'axios';

export default {
    name: 'NewsPage',
    data() {
        return {
            news: []
        };
    },
    created() {
        axios.get('/api/news')
        .then(response => {
            this.news = response.data;
        })
        .catch(error => {
            console.error('There was an error fetching the news: ', error);
        });
    }
};
</script>