import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import NewsPage from '../views/NewsPage.vue';
import UserLogin from '../components/UserLogin.vue';
import UserRegister from '../components/UserRegister.vue';


const routes = [
    { path: '/', name: 'HomePage', component: HomePage},
    { path: '/news', name: 'NewsPage', component: NewsPage},
    { path: '/login', name: 'UserLogin', component: UserLogin},
    { path: '/register', name: 'UserRegister', component: UserRegister},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router;