<template>
  <div id="app" class="bg-dark">
    <NavBar/>
    <hr class="hr mb-5" />
    <router-view class="vh-80" /> <!-- Ceci affichera le composant lié à la route active -->
    <FooterBar />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
  name: 'App',
  components: {FooterBar, NavBar},
};
</script>

<style>
.vh-80 {
  height: 80vh;
}
</style>