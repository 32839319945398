<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-body-tertiary text-muted mt-xxl-5">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <!-- Left -->
      <div class="mx-auto">
        <span>Rose Online Revolution is a Free to Play 3D Fantasy Anime MMORPG based on ROSE Online. Free to register, download, and play.</span>
      </div>

    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2024 Copyright:
      <a class="text-reset fw-bold" href="https://rose.jibestudio.net/">Rose Online Revolution</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</template>

<script setup lang="ts">
</script>