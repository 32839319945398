<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col">
        <div class="carousel slide" id="carouselExampleIndicators" data-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../../public/img/header.jpg" width="800" height="400" alt="First slide">
              <div class="carousel-caption d-none d-md-block text-light">
                <h5>It's coming !</h5>
                <p>Halloween event is in preparation...</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="../../public/img/ROSE-Online-logo640-640x300.jpg" width="800" height="400" alt="Second slide">
              <div class="carousel-caption d-none d-md-block text-light">
                <h5>Rose Online Revolution is soon coming to life !</h5>
                <p>Soon, Rose Online Revolution will open its doors</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="text-center">
          <div class="patch mt-5 overflow-auto custom-scrollbar-css p-2">
            <h2 class="title">Patch Notes :</h2>
            <div class="patch-notes">
              <div class="patch1">
                <h4 class="updateTitle">Update 1:</h4>
                <ul class="list-group">
                  <li class="list-group-item">Fixed background rendering</li>
                  <li class="list-group-item">Fixed save in database</li>
                  <li class="list-group-item">Client can accept more resolutions</li>
                </ul>
              </div>
              <div class="patch1">
                <h4 class="updateTitle">Update 2:</h4>
                <ul class="list-group">
                  <li class="list-group-item">Developped a launcher</li>
                  <li class="list-group-item">Modified window name</li>
                  <li class="list-group-item">Added more distance to camera view</li>
                </ul>
              </div>
              <div class="patch1">
                <h4 class="updateTitle">Update 3:</h4>
                <ul class="list-group">
                  <li class="list-group-item">Fixed skillbars</li>
                  <li class="list-group-item">Removed WASD</li>
                  <li class="list-group-item">Updated Launcher visual</li>
                  <li class="list-group-item">Fixed shake, it was way too shaky</li>
                  <li class="list-group-item">Fixed login screen and logo</li>
                  <li class="list-group-item">Fixed Darren postion, he is no longer on top of the fountain</li>
                  <li class="list-group-item">Fixed skillbars still showing F1, F2, F3...</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.patch {
  margin: auto;
  max-width: 60rem;
  max-height: 50vh;
}

.patch1,
.patch2 {
  margin-top: 3rem;
}

/* Custom Scrollbar using CSS */
.custom-scrollbar-css {
  overflow-y: scroll;
}

/* scrollbar width */
.custom-scrollbar-css::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar track */
.custom-scrollbar-css::-webkit-scrollbar-track {
  background: #eee;
}

/* scrollbar handle */
.custom-scrollbar-css::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #00d2ff;
  background-image: linear-gradient(to top, #00d2ff 0%, #3a7bd5 100%);
}
</style>

<script>
export default {
name: 'HomePage',
    }
</script>